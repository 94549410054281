import React from 'react'
import './summary.less'
import * as Redux from 'redux'
import { connect } from 'react-redux'

import SignButtonSummary from 'components/onboarding/components/signButtonSummary'
import MemberOfferSummary from 'components/onboarding/components/memberOfferSummary'
import { get, times } from 'lodash'
import { fetchMontlyFee } from 'components/onboarding/views/summary/actions'
import {
  setAnswerActionCreator,
  UnformattedUserInput,
  setUserCreationError,
} from 'store/onboarding/actions'
import {
  getTunnelAnswer,
  getOnboardingAnswersValue,
  getUserDetails,
  getUserDetail,
} from 'store/signup/selectors'
import {
  entranceFee,
  invoiceFee,
  monthlySum,
  oneTimeSum,
  unemploymentFundFee,
  unionFee,
} from 'store/onboarding/selectors'
import { ApplicationState } from '../../store'
import {
  cancelBankIdProcess,
  fetchSignStatus,
  initiateBankIdSign,
} from 'components/onboarding/bankID/actions'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'components/checkbox/checkbox'
import { isSmartphone } from '../../utils/deviceUtils'
import { navigate } from '@reach/router'
import { createUser } from '../../store/onboarding/actions'

import { getInitialSignUpPath, getTunnelBase } from './tunnel/selectors'

import SummaryMetaBox from 'components/onboarding/components/summaryMetaBox'
import { OnboardingActionTypes } from '../../store/onboarding/types'
import * as R from 'ramda'
import { BankIdActionTypes } from './bankID/types'
import { SignupFormValue } from 'store/signup/actions'
import { AuthState } from 'store/auth/types'
import InterestCodeContext from './InterestCodeContext'
import Modal from 'components/modal/Modal'

interface StateProps {
  auth: AuthState
  autoStartToken?: string
  endpoint?: string
  entranceFee?: number
  freemium?: boolean
  headPath: string
  invoiceFee?: number
  isComplete?: boolean
  isMobile: boolean
  launchUrl?: string
  monthlySum?: number
  needHelpWithAkassa: boolean
  addAkassa: boolean
  occupationTypeId: string
  orderRef?: string
  oneTimeSum?: number
  onboardingAnswers: { akassa: boolean }
  retrySeconds?: number
  sign?: boolean
  statusMessage?: string
  qrCode?: string
  salary: string
  unemploymentFundFee?: number
  userCreated: boolean
  userCreationError?: {}
  userdetails?: {
    swedishPersonalIdentityNumber: string
    phone: string
    email: string
  }
  unionFee?: number
  signupPath: string
}

interface DispatchProps {
  fetchMonthlyFee: (payload: object) => void
  initiateSigning: (payload: object) => void
  fetchSignStatus: (
    orderRef: string,
    endpoint: string,
    isSwedishPersonalIdentityNumberProvided: boolean,
    autoLaunch: boolean,
  ) => void
  createUser: (type: string, payload: UnformattedUserInput) => void
  cancel: (orderRef: string) => void
  setAnswer: (key: string, value: SignupFormValue) => void
  clearBankID: () => void
}

interface InternalProps {
  state: string
  interval?: any
  tocChecked: boolean
  completedOrderRef?: string
  privacyPolicyChecked: boolean
  isSubmitting?: boolean
  checkedHelp: boolean
  onCheckedHelp: (value: boolean) => void
  location: any
  disableGTMEvent: boolean
  startFailed: boolean
  timesInitialized: number
}

export enum SummaryStates {
  PRESIGN = '@@sign/presign',
  SIGN_MEMBERSHIP = '@@sign/membership',
  SIGN_FREEMIUM = '@@sign/freemium',
}

const getState = (state: string) => {
  const signMembership = state === SummaryStates.SIGN_MEMBERSHIP
  const signFreemium = state === SummaryStates.SIGN_FREEMIUM
  const signMembershipOrFreemium = signMembership || signFreemium

  return { signMembership, signFreemium, signMembershipOrFreemium }
}

type Props = StateProps & DispatchProps & InternalProps // TODO: Look into how it's supposed to be since we now have internal props twice!!!!

const acceptTerms = (
  labelTextId: string,
  labelUrlId: string,
  callback: (checked: boolean) => void,
  style?: React.CSSProperties,
  customContent?: JSX.Element,
) => (
  <div className="priceSummary-sign-checkbox" style={style}>
    <Checkbox
      customContent={customContent}
      labelId={labelTextId}
      labelUrl={labelUrlId}
      onCheck={callback}
    />
  </div>
)

const userDetails = (
  personalNumber: string,
  email: string,
  telephone: string,
  occupationTypeId: string,
) => {
  return (
    <>
      <section className={`signSummary`}>
        <p className="signSummary-heading">
          <FormattedMessage id={`userSummary.heading`} />
        </p>
        <div className="Grid">
          <div className="u-xsm-size1of2">
            <p className="signSummary-label">
              <FormattedMessage id={`labels.mobileNumber`} />
            </p>
            <p className="u-marginTz">{telephone}</p>
          </div>
          <div className="u-xsm-size1of2">
            <p className="signSummary-label">
              <FormattedMessage id={`labels.email`} />
            </p>
            <p className="u-marginVz">{email}</p>
          </div>
        </div>
      </section>
    </>
  )
}

class Summary extends React.Component<
  Props,
  InternalProps & { autoStartBankId: boolean; pendingUserCreation: boolean }
> {
  static contextType = InterestCodeContext
  context!: React.ContextType<typeof InterestCodeContext>

  static defaultProps = {
    state: OnboardingActionTypes.CREATE_DIK_USER,
  }
  constructor(props: Props) {
    super(props)
    this.state = {
      tocChecked: false,
      privacyPolicyChecked: false,
      autoStartBankId: false,
      completedOrderRef: '',
      pendingUserCreation: false,
      timesInitialized: 0,
    }
  }

  componentDidMount(): void {
    const { state } = this.props.location
    state && state.wasNavigated == true ? null : navigate(`/${this.props.headPath}`)

    const onboardingAnswers = this.convertAnswersForSubmit(this.props.onboardingAnswers)

    if (this.props.occupationTypeId) {
      this.props.fetchMonthlyFee({
        ...this.props.userdetails,
        ...onboardingAnswers,
      })
    }
    this.props.setAnswer('addHelpService', this.props.checkedHelp)
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps === this.props) {
      return
    }

    if (!prevProps.startFailed && this.props.startFailed) {
      if (this.state.timesInitialized < 5) {
        this.setState((prevState) => ({
          timesInitialized: prevState.timesInitialized + 1,
        }))

        this.initiateSign(this.state.autoStartBankId)()
      } else {
        console.warn('Failed to start session 5 times in a row. Please try again later.')
      }
    }

    if (
      this.state.autoStartBankId &&
      this.props.launchUrl != null &&
      prevProps.launchUrl !== this.props.launchUrl
    ) {
      window.location.href = this.props.launchUrl
    }
    const orderRefIsAssigned =
      this.props.orderRef != null && this.props.orderRef != prevProps.orderRef
    if (orderRefIsAssigned) {
      const intervalTime = (this.props?.retrySeconds ?? 2) * 1000
      const interval = setInterval(
        () =>
          this.props.orderRef &&
          this.props.fetchSignStatus(
            this.props.orderRef,
            this.props.endpoint,
            false,
            this.state.autoStartBankId,
          ),
        intervalTime,
      )
      this.setState({
        interval: interval,
        completedOrderRef: this.props.orderRef,
      })

      setTimeout(() => {
        if (this.state.interval) {
          clearInterval(this.state.interval)
        }
      }, 500 * 1000)
    }

    if (prevProps.orderRef != null && this.props.orderRef == null) {
      clearInterval(this.state.interval)
    }

    // Successful sign with BankID
    if (
      !prevProps.isComplete &&
      this.props.isComplete &&
      !this.props.userCreated &&
      this.props.userdetails
    ) {
      clearInterval(this.state.interval)

      this.props.createUser(this.props.state as string, {
        interestCodes: this.context.interestCodes
          .filter((code) => code.selected)
          .map((code) => code.id),
        ...this.props.userdetails,
        ...this.convertAnswersForSubmit(this.props.onboardingAnswers),
        completedOrderRef: this.state.completedOrderRef,
      })
      this.setState({ pendingUserCreation: true })
    }

    if (this.props.occupationTypeId && this.props.occupationTypeId === 'student') {
      sessionStorage.setItem('occupation', 'student')
    }

    if (this.props.userCreated) {
      this.props.clearBankID()
      navigate(`/${this.props.headPath}/welcome`, {
        state: { wasNavigated: true },
      })
    }

    if (this.props.userCreationError && !prevProps.userCreationError) {
      this.setState({ pendingUserCreation: false })
      this.props.clearBankID()
    }
  }

  //check for any whitespace character, e.g. "20 000 , 20.000" etc
  cleanUpNumbersInString = (value: string): string => {
    return value.replace(/[\s.,]/g, '')
  }

  //       salary: answers.inkomst ?? this.cleanUpNumbersInString(answers.lon),
  convertAnswersForSubmit = (answers: any) => {
    return {
      addHelpService: answers.addHelpService,
      workplace: answers.arbetsplats,
      salary:
        answers.sysselsattning !== 'STU'
          ? answers.inkomst ?? this.cleanUpNumbersInString(answers.lon)
          : undefined,
      jobTitle: answers.roll,
      industry: answers.sektor,
      unemploymentFund: answers.akassa,
      occupationTypeId: answers['freelance-type'] ?? answers.sysselsattning,
    }
  }

  initiateSign = (autoStart: boolean) => () => {
    const { swedishPersonalIdentityNumber } = this.props.userdetails ?? {
      swedishPersonalIdentityNumber: undefined,
    }

    if (this.state.tocChecked && this.state.privacyPolicyChecked) {
      this.props.initiateSigning({
        signMessage: 'Dik',
        signReason: this.props.state === SummaryStates.SIGN_FREEMIUM ? 'joindikstart' : 'joinunion',
        redirectUrl: 'null',
        autoLaunch: autoStart,
        swedishPersonalIdentityNumber,
      })
    }
    this.setState({ autoStartBankId: autoStart })
  }

  onCheckTerms = (checked: boolean) => {
    this.setState({ tocChecked: checked })
  }
  onCheckPrivacyPolicy = (checked: boolean) => {
    this.setState({ privacyPolicyChecked: checked })
  }

  calculateFee = () => {
    if (!this.props.monthlySum) {
      return false
    }

    return this.props.addAkassa || this.props.onboardingAnswers.akassa === true
      ? String(this.props.monthlySum)
      : String(get(this, 'props.unionFee', 0))
  }

  render() {
    const { state, isComplete, statusMessage, qrCode, oneTimeSum, orderRef, occupationTypeId } =
      this.props
    const { phone, swedishPersonalIdentityNumber, email } = this.props.userdetails
    const { signMembershipOrFreemium } = getState(state)
    const userdetailsElement = userDetails(
      swedishPersonalIdentityNumber,
      email,
      phone,
      occupationTypeId,
    )
    const acceptTermsElement = acceptTerms(
      '',
      '',
      this.onCheckTerms,
      { marginBottom: 10 },
      <>
        {this.props.userCreationError && (
          <Modal
            keyProp="userCreateError"
            onClose={() => this.props.clearUserCreationError()}
            closeButton
          >
            <h2>Kunde inte slutföra medlemsansökan</h2>
            <p>
              Ett fel inträffade i behandlingen av din medlemsansökan och den kan inte slutföras på
              webben. Kontakta{' '}
              <a href="/dik-direkt/kontakta-oss" className="Link">
                DIK Direkt
              </a>{' '}
              via e-post eller telefon för att slutföra ansökan.
            </p>
          </Modal>
        )}
        {this.props.state !== SummaryStates.SIGN_FREEMIUM ? (
          <>
            <FormattedMessage id={`sign.toc-desc.accept`} />
            <a
              className="Link"
              target="_blank"
              href="https://assets.ctfassets.net/vkygthqfrquy/78b4jMNcfY8dTAEFwW3WLK/fb72ca867c3b860b2f7c5332bce399a7/diks-stadga-antagen-av-diks-kongress-24-nov-2018.pdf"
              /*href="/om-oss/villkor-och-stadgar"*/
            >
              <FormattedMessage id={`sign.toc-desc.rules`} />
            </a>
            <FormattedMessage id={`sign.toc-desc.and`} />
            <a
              className="Link"
              target="_blank"
              href="/om-oss/villkor-och-policyer/villkor-for-medlemskap-dik-prio"
            >
              <FormattedMessage id={`sign.toc-desc.terms`} />
            </a>
            <FormattedMessage id={`sign.toc-desc.submitPrio`} />
          </>
        ) : (
          <>
            <FormattedMessage id={`sign.toc-desc.accept`} />
            <a
              className="Link"
              target="_blank"
              href="/om-oss/villkor-och-policyer/villkor-for-dik-start"
            >
              <FormattedMessage id={`sign.toc-desc.termsStart`} />
            </a>
            <FormattedMessage id={`sign.toc-desc.submitStart`} />
          </>
        )}
      </>,
    )

    const acceptPrivacyPolicyElement = acceptTerms(
      'sign.privacy-policy',
      'sign.privacy-policy-link',
      this.onCheckPrivacyPolicy,
      { marginTop: 10 },
    )
    const fee = this.calculateFee()

    return (
      <>
        {/* Userdetails mobile  */}
        {signMembershipOrFreemium && (
          <div className="userdetails__mobile">{userdetailsElement}</div>
        )}

        <section
          className={`u-colorBgWhite u-marginVlg priceSummary ${
            this.state.isSubmitting ? 'is_submitting' : ''
          }`}
        >
          <div
            className={`u-colorBgWhite priceSummary ${
              signMembershipOrFreemium ? 'u-flexSummaryRowReverse' : ''
            }`}
          >
            {/* The meta box displaying the monthly fee for a membership */}
            {this.props.state !== SummaryStates.SIGN_FREEMIUM ? (
              <SummaryMetaBox
                perMonth={fee}
                oneTimeSum={oneTimeSum}
                needHelpWithAkassa={
                  this.props.addAkassa || this.props.onboardingAnswers.akassa === true
                }
              />
            ) : (
              <SummaryMetaBox freemium={true} />
            )}
            {/* The meta box teasing DIK-membership */}

            <div className="u-sizeFull">
              {/* The plain version where the click only initiates the signup */}
              {!signMembershipOrFreemium && (
                <>
                  <MemberOfferSummary
                    oneTimeSum={oneTimeSum}
                    includeAkassa={this.props.needHelpWithAkassa}
                    occupationTypeId={this.props.occupationTypeId}
                  />

                  <SignButtonSummary
                    disableGTMEvent={this.props.disableGTMEvent}
                    signupPath={this.props.signupPath}
                    isBankId={signMembershipOrFreemium}
                  />
                </>
              )}

              {/* The mobile version of the user details */}
              {signMembershipOrFreemium && (
                <div className="userdetails__desktop">{userdetailsElement}</div>
              )}

              {/* Accept terms & conditions, only when we sign with bankId */}
              {signMembershipOrFreemium && acceptTermsElement}
              {signMembershipOrFreemium && acceptPrivacyPolicyElement}

              {signMembershipOrFreemium &&
                this.props.occupationTypeId &&
                this.props.occupationTypeId === 'student' && (
                  <div className="priceSummary-help u-marginTsm">
                    <h6>Just nu gratis för studenter! </h6>

                    <p>
                      Vi bjuder på medlemsavgiften under hela studietiden – spara 100 kr. Gäller 1
                      augusti–31 oktober 2024.{' '}
                    </p>
                  </div>
                )}
              {/* Sign buttons for executing bankId */}
              {signMembershipOrFreemium && (
                <SignButtonSummary
                  disableGTMEvent={this.props.disableGTMEvent}
                  isBankId={signMembershipOrFreemium}
                  pendingSignature={this.props.orderRef != null || this.state.pendingUserCreation}
                  abort={() => this.props.orderRef && this.props.cancel(this.props.orderRef)}
                  statusMessage={!isComplete ? statusMessage : ''}
                  qrCode={qrCode}
                  onClick={this.initiateSign}
                  checked={this.state.tocChecked && this.state.privacyPolicyChecked}
                />
              )}
            </div>
          </div>
        </section>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  fetchMonthlyFee: (payload: object) => dispatch(fetchMontlyFee(payload)),
  initiateSigning: (payload: object) => dispatch(initiateBankIdSign(payload)),
  createUser: (userType: string, payload: UnformattedUserInput) =>
    dispatch(createUser(userType, payload)),
  cancel: (orderRef: string) => dispatch(cancelBankIdProcess(orderRef)),
  clearBankID: () => dispatch({ type: BankIdActionTypes.CLEAR }),
  fetchSignStatus: (
    orderRef: string,
    endpoint: string,
    isSwedishPersonalIdentityNumberProvided: boolean,
    autoLaunch: boolean,
  ) => {
    dispatch(
      fetchSignStatus(orderRef, endpoint, isSwedishPersonalIdentityNumberProvided, autoLaunch),
    )
  },
  setAnswer: (key: string, answer: string) => dispatch(setAnswerActionCreator(key, answer)),
  clearUserCreationError: () => dispatch(setUserCreationError()),
})

const mapStateToProps = (state: ApplicationState): StateProps => ({
  auth: state.auth,
  autoStartToken: state.bankId.autoStartToken,
  endpoint: state.bankId.endpoint,
  entranceFee: entranceFee(state),
  invoiceFee: invoiceFee(state),
  isMobile: isSmartphone(),
  isComplete: state.bankId.isCompleted,
  launchUrl: state.bankId.launchUrl,
  needHelpWithAkassa: getUserDetail(state)('hjalp-med-akassa'),
  addAkassa: getUserDetail(state)('addAkassa'),
  monthlySum: monthlySum(state),
  oneTimeSum: oneTimeSum(state),
  onboardingAnswers: getOnboardingAnswersValue(state),
  orderRef: state.bankId.orderRef,
  autoLaunch: state.bankId.autoLaunch,
  completedOrderRef: state.bankId.completedOrderRef,
  occupationTypeId: R.propOr('', 'id', getTunnelAnswer(state)('sysselsattning')),
  retrySeconds: state.bankId.retrySeconds,
  salary: getTunnelAnswer(state)('inkomst') ?? getTunnelAnswer(state)('lon'),
  statusMessage: state.bankId.statusMessage,
  qrCode: state.bankId.qrCode,
  unemploymentFundFee: unemploymentFundFee(state),
  userCreated: state.navigation.userCreated,
  userCreationError: state.navigation.userCreationError,
  freemiumUserCreated: state.navigation.freemiumUserCreated,
  userdetails: getUserDetails(state),
  unionFee: unionFee(state),
  signupPath: getInitialSignUpPath(state),
  headPath: getTunnelBase(state),
  startFailed: state.bankId.startFailed,
})

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(Summary)
